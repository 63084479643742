import create from 'zustand';
import axios from 'axios';
import environmnet from '../env.json';

let BACKEND_SERVER = "http://localhost:8800/dev"
if (environmnet.REACT_APP_BACKEND_SERVER && environmnet.NODE_ENV) {
    BACKEND_SERVER = environmnet.REACT_APP_BACKEND_SERVER + "/" + environmnet.NODE_ENV;
}

export const useAuthDataStore = create((set) => ({
    authToken: '',
    loginUser: async (credentials) => {
        try {
            const res = await fetch(`${BACKEND_SERVER}/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(credentials)
            });
            let json_res = await res.json();
            set({authToken: json_res.token});
            localStorage.setItem('authToken', json_res.token);
        } catch (err) {
            console.error('Error fetching data:', err);
        }
    },
    logoutUser: async () => {
        localStorage.removeItem('authToken');
    }
}))

export const usePartyDataStore = create((set) => ({
    partyList: [],
    isPartyListLoading: false,
    getPartyList: async (partyType) => {
        try {
            set({isPartyListLoading: true});
            const storedToken = localStorage.getItem('authToken');
            if (storedToken) {
                const res = await fetch(`${BACKEND_SERVER}/api/master/getpartylist?partyType=${partyType}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${storedToken}`,
                    }
                });

                if (res.status === 401) {
                    localStorage.removeItem('authToken');
                    window.location.href = '/';
                }

                let json_res = await res.json()
                set({ partyList: json_res.data });
                set({isPartyListLoading: false});
            } else {
                console.log('Token not found');
            }
        } catch (err) {
            console.error('Error fetching data:', err);
        }
    },
}))

export const useDataByPartyStore = create((set) => ({
    aggDataByParty: {},
    aggChartDataByParty: [],
    isAggPartyDataLoading: false,
    getAggDataByParty: async (partyId) => {
        try {
            set({isAggPartyDataLoading: true});
            const storedToken = localStorage.getItem('authToken');
            if (storedToken) {
                const res = await fetch(`${BACKEND_SERVER}/api/stock-ledger/getaggregateddatabyparty/${partyId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${storedToken}`,
                    }
                });

                if (res.status === 401) {
                    localStorage.removeItem('authToken');
                    window.location.href = '/';
                }

                let json_res = await res.json();
                set({ aggDataByParty: json_res.data[0] });
                set({isAggPartyDataLoading: false});
            } else {
                console.log('Token not found');
            }
        } catch (err) {
            console.error('Error fetching data:', err);
        }
    },
    getAggChartdataByParty: async (partyId, startMonth, startYear, endMonth, endYear) => {
        try {
            set({isAggPartyDataLoading: true});
            const storedToken = localStorage.getItem('authToken');
            if (storedToken) {
                const res = await fetch(`${BACKEND_SERVER}/api/stock-ledger/getchartdatabymonth/${partyId}?startMonth=${startMonth}&startYear=${startYear}&endMonth=${endMonth}&endYear=${endYear}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${storedToken}`,
                    }
                });

                if (res.status === 401) {
                    localStorage.removeItem('authToken');
                    window.location.href = '/';
                }

                let json_res = await res.json()
                set({ aggChartDataByParty: json_res.data });
                set({isAggPartyDataLoading: false});
            } else {
                console.log('Token not found');
            }
        } catch (err) {
            console.error('Error fetching data:', err);
        }
    }
}))

export const useTrxnStore = create((set) => ({
    trxnsData: [],
    isTrxnsDataLoading: false,
    getTrxnsData: async (partyId, startDate, endDate) => {
        try {
            set({isTrxnsDataLoading: true});
            const storedToken = localStorage.getItem('authToken');
            if (storedToken) {
                const res = await fetch(`${BACKEND_SERVER}/api/stock-ledger/gettransactions/${partyId}?startDate=${startDate}&endDate=${endDate}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${storedToken}`,
                    }
                });

                if (res.status === 401) {
                    localStorage.removeItem('authToken');
                    window.location.href = '/';
                }

                let json_res = await res.json()
                set({ trxnsData: json_res.data });
                set({isTrxnsDataLoading: false});
            } else {
                console.log('Token not found');
            }
        } catch (err) {
            console.error('Error fetching data:', err);
        }
    },
    addAddedTransactions: (trxn) => set((state) => {
        if (trxn.constructor.name === 'Object') {
            return {...state, trxnsData: [...state.trxnsData, trxn]}
        } else if (trxn.constructor.name === 'Array') {
            return {...state, trxnsData: [...state.trxnsData, ...trxn]}
        }
    }),
    removeDeletedTransaction: (trxn) => set((state) => {
        // let updatedTrxns = state.trxnsData.filter(val => val.trxnId !== trxn.trxnId);
        // return {trxnsData: [...updatedTrxns]}
        let trxns = [...state.trxnsData]
        let deleteIndex = trxns.findIndex(val => val.trxnId === trxn.trxnId);
        trxns[deleteIndex] = {...trxn, type: 'deleted-trxns'}
        return {trxnsData: [...trxns]}
    }),
    updateTransaction: (trxn) => set((state) => {
        let trxns = [...state.trxnsData]
        let updateIndex = trxns.findIndex(val => val.trxnId === trxn.trxnId);
        trxns[updateIndex] = trxn;
        return {trxnsData: [...trxns]}
    }),
    cudTransactions: async (insertEntries, updateEntries, deleteEntries) => {
        try {
            const storedToken = localStorage.getItem('authToken');
            if (storedToken) {
                const res = await axios.post(`${BACKEND_SERVER}/api/stock-ledger/save`, { updateEntries, deleteEntries, insertEntries }, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${storedToken}`,
                    }
                });

                if (res.response && res.response.status === 401) {
                    localStorage.removeItem('authToken');
                    window.location.href = '/';
                }
                return res;

            } else {
                console.log('Token not found');
            }
        } catch (err) {
            if (err.response && err.response.status === 401) {
                localStorage.removeItem('authToken');
                window.location.href = '/';
            }
            console.error('Error fetching data:', err);
        }
    }
}))

